@import "../../fluidType.scss";
.mainPage {
  .selectTokensWrapper {
    padding-top: 15px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
    @media (max-width: 860px) {
      flex-direction: column;
    }
    .mainLabel {
      @include fluidType(font-size, 390px, 1365px, 56px, 52px);
      @include fluidType(line-height, 390px, 1365px, 67px, 71px);
      @include fluidType(width, 390px, 1365px, 315px, 474px);


      margin-right: 30px;
      margin-bottom: 24px;
      font-weight: 400;
      @media (max-width: 1045px) and (min-width: 860px) {
        font-size: 40px !important;
        word-spacing: -10px!important;
        margin-right: 8px !important;
      }
      @media (max-width: 540px) {
        width: 100%;
      }
      @media (max-width: 390px) {
        font-size: 40px !important;
        line-height: 60px;
        word-spacing: -10px!important;
        margin-right: 0 !important;
      }

      .mainSubLabel {
        max-width: 400px;
        margin-top: 16px;
        font-size: 14px;
        line-height: 16.8px;
        font-weight: 500;
        opacity: 0.6;
        @media (max-width: 390px) {
          word-spacing: 50px!important;
        }
        @media (max-width: 540px) {
          width: 100%;
          max-width: 100%;
          margin-right: 0;
        }
        @media (max-width: 390px) {
          word-spacing: 5px!important;

        }
        .mainSubLabelBr {
          @media (max-width: 540px) {
            display: none;
          }
        }

      }
    }
  }
}

.positionsWrapper {
  display: flex;
  margin-bottom: 80px;
  @media (max-width: 860px) {
    flex-direction: column;
  }
}

