.button {
  width: 100%;
  border-radius: 50px;
  padding: 12px 0;
  background-color: var(--lightYellow);
  font-size: 24px;
  line-height: 24px;
  font-weight: 500;
  transition: all, 0.2s linear;

  &:disabled {
    opacity: 1;
    background-color: white;
    color: black;
    cursor: auto;
    &:hover {
      background-color: white;
      transform: scale(1);
    }
  }
  &:hover {
    background-color: var(--darkYellow);
    transform: scale(1.03);
  }
}

.buttonBlack {
  font-weight: 500;
  background-color: black;
  color: white;
  transition: all, 0.2s linear;
  &:disabled {
    opacity: 1;
    background-color: white;
    color: black;
    cursor: auto;
    &:hover {
      background-color: white;
      transform: scale(1);
    }
  }
  &:hover {
    background-color: black;
    transform: scale(1.03);

  }
}

.loader {
  width: 20px;
  height: 20px;
  border: 3px solid white;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loaderBlack {
  width: 20px;
  height: 20px;
  border: 3px solid black;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}