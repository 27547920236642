.connectWrapper {
  border-radius: 24px;
  background-color: #FDFDFD;
  width: 420px;
  height: 300px;
  padding: 24px 24px 0;

  @media (max-width: 560px) {
    width: 390px;
  }
  @media (max-width: 390px) {
    width: 320px;
    padding: 12px 12px 0;
  }

}

.modalLabel {
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  margin-bottom: 25px;

  @media (max-width: 560px) {
    margin-bottom: 32px;
  }
}

.walletItem {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid black;
  border-radius: 16px;
  cursor: pointer;
  transition: all 0.1s linear;
  &:hover {
    background-color: #ededed;
  }

  .walletItemImg {
    width: 25px;
    height: 25px;
    margin-right: 20px;
    margin-left: 20px;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .walletItemText {
    flex-basis: 80%;
    font-size: 20px;
    line-height: 26px;
  }
}


.walletItemActive {
  background-color: #dcdcdc;
  cursor: auto;
  border: 1px solid var(--lightYellow);
  &:hover {
    background-color: #dcdcdc;
  }
}