@import "../../fluidType.scss";
.mainContainer {
  max-width: 1365px !important;
  margin: 30px auto 20px;
  padding-left: 24px;
  @media (max-width: 860px) {
    padding-left: 8px;
  }
  min-height: calc(100vh - 400px);
}
