@import "../../fluidType.scss";
.footer {
  @include fluidType(height, 390px, 1365px, 283px, 436px);
  position: relative;
  .footerBg {
    width: 100%;
    height: 100%;
  }
  .footerContent {
    margin-left: 15px;
    position: absolute;
    bottom: 8px;
    z-index: 10;
    @include fluidType(width, 390px, 612px, 357px, 580px);
    height: 235px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 16px;
    padding-bottom: 16px;
    @include fluidType(padding-left padding-right, 390px, 560px, 16px, 24px);
    @media (max-width: 560px) {
      margin-left: 0;
      top: 16px;
      left: 50%;
      transform: translateX(-50%);
    }
    @media (max-width: 390px) {
      width: auto;
    }
    .footerContentLabel {
    }
    .footerNav {
      .footerNavList {
        .footerNavItem {
          display: flex;
          margin-bottom: 19px;
          @media (max-width: 560px) {
            flex-wrap: wrap;
            margin-bottom: 24px;
          }
          &:last-child {
            margin-bottom: 0;
          }

          .footerNavInfo {
            display: block;
            letter-spacing: -0.4px !important;
            margin-right: 6px;
            opacity: 0.4;
            width: 220px;
            padding-bottom: 8px;
            font-weight: 500;
            @media (max-width: 560px) {
              flex-basis: 100%;
            }
          }
          .footerNavLinks {
            display: flex;
            .footerNavLink {
              letter-spacing: -0.3px !important;
              color: inherit;
              font-weight: 500;
              padding-right: 16px;
              &:last-child {
                padding-right: 0;
              }
            }
          }
        }
      }
    }
  }
}
