@import "../../fluidType.scss";

.positionTokensWrapper {
  margin-top: 80px;
  border-radius: 24px;
  background-color: white;
  padding: 28px 24px 24px;
  width: 100%;
  max-width: 811px;
  max-height: 310px;
  overflow: scroll;
  @include fluidType(margin-right, 1363px, 1365px, 0, 24px);
  white-space: nowrap !important;
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin-right: 0;
  margin-left: auto;

  @media (max-width: 1366px) {
    margin-right: 24px;
  }

  @media (max-width: 860px) {
    margin-right: 0;
    margin-left: 0;
  }

  @media (max-width: 820px) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }



  margin-bottom: 24px;
  &::-webkit-scrollbar {
    display: none;
  }

  .positionTabs {
    display: flex;
    width: 755px;

  }

  .tabLabel {
    font-size: 12px;
    font-weight: 500;
    line-height: 14.4px;

  }
}

.positionTokensWrapperWide {
  @media (max-width: 860px) {
    margin-top: 0;
  }
}


.positionsLabel {
  display: block;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 16px;
}


.name {
  flex-basis: 150px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  padding-right: 10px;
}

.amount {
  max-width: 350px;
  min-width: 350px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  padding-right: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pool {
  max-width: 157px;
  min-width: 157px;
  padding-right: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
}

.endsOf {
  max-width: 100px;
  min-width: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-left: 10px;
}

.positionTabs {
  font-size: 14px;
  font-weight: 500;
  line-height: 14.4px;
  padding-bottom: 8px;
  border-bottom: 1px solid #C2C2C2;
}

.positionListWrapper {
  max-height: 360px;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  .positionListItem {
    height: 30px;
    display: flex;
    margin-bottom: 12px;
    &:last-child {
      margin-bottom: 0;
    }
    &:first-child {
      margin-top: 5px;
    }
  }
}

.positionListItemText {
  font-family: "SF Mono Medium", monospace;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.05px;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.tokenLogos {
  display: flex;
  align-items: center;
  margin-right: 9px;

  .tokenLogoImgFirst {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: var(--milkShake);
  }

  .tokenLogoImgSecond {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    margin-left: -5px;
    background-color: var(--milkShake);
  }
}

.unlockBtn {
  background-color: black;
  color: white;
  font-size: 14px;
  width: 84px;
  line-height: 16px;
  font-family: "SF Mono Regular", monospace;
  border-radius: 30px;
  padding: 8px 0px;
  transition: all 0.2s linear;
  &:disabled {
    color: #c7c7c7;
  }

  &:hover {
    transform: scale(1.03);
  }
}
.amountText {
  display: inline-block;
  max-width: 260px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.amountContainer {
  display: flex;
  align-items: center;
  .amountTextUsd {
    display: inline-block;
    max-width: 115px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.space {
  width: 5px;
}

.loader {
  width: 13px;
  height: 13px;
  border: 2px solid white;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loaderWrapper {
  height: 290px;
  display: flex;
  align-items: center;
  justify-content: center;

  .loaderBlack {
    width: 40px;
    height: 40px;
    border: 3px solid black;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    margin-top: -28px;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }

}

