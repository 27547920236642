*,
*::before,
*::after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
  border: 0;
}

:focus, :active {
  outline: none;
}

a:focus, a:active {
  outline: none;
}

nav, footer, header, aside {
  display: block;
}

html, body {
  line-height: 1;
  font-size: 14px;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-family: 'Roboto Mono', monospace;
}

body {
  scroll-behavior: smooth;
  background-color: var(--mainBgColor);
  text-transform: uppercase;
  letter-spacing: 0.03em;
  font-size: 12px;
  line-height: 14px;
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: lightgrey;
  }
  &::-webkit-scrollbar-thumb {
    background-color: grey;
    border-radius: 16px;
  }
}


.onActiveModal {
  overflow: hidden;
}

input, button, textarea, select {
  font-style: inherit;
}

input::placeholder {
  text-transform: inherit;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
}

button {
  cursor: pointer;
  font-family: inherit;
  background-color: transparent;
  text-transform: inherit;
}

a, a:visited, a:hover {
  text-decoration: none;
}

ul, li {
  list-style-type: none;
}

img {
  vertical-align: top;
  display: block;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: 400;
}


:root {
  --mainBgColor: #EBEBEB;
  --darkBlue: #0033E7;
  --lightYellow: #F9D549;
  --darkYellow: #eaca4c;
  --milkShake: #e3e3e3;
  --darkGreen: #1BA27A;
}

@font-face {
  font-family: "SF Mono Regular";
  src: url("fonts/SF Mono/SFMono-Regular.woff2");
}

@font-face {
  font-family: "SF Mono Medium";
  src: url("fonts/SF Mono/SFMono-Medium.woff2");
}

@font-face {
  font-family: "SF Mono Bold";
  src: url("fonts/SF Mono/SFMono-Bold.woff2");
}

