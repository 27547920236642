.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 200;
  padding: 32.5px 26px 32.5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  @media (max-width: 640px) {
    background-color: var(--mainBgColor);
  }
  @media (max-width: 420px) {
    padding: 32.5px 12px;
  }

  .leftContainer {
    display: flex;
    align-items: center;

    .burgerMenu  {
      margin-right: 10px;
      cursor: pointer;
    }

    .mainLogo {
      cursor: pointer;
      margin-top: 3px;
    }
  }
  .headerWalletWrapper {
    display: flex;
    align-items: center;
    .dexLink {
      margin-left: 15px;
      margin-right: 15px;
      font-size: 18px;
      line-height: 18px;
      color: black;
      letter-spacing: -0.03em;
      font-family: "Roboto Mono", monospace;
      font-weight: 400;
      transition: all 0.1s linear;
      word-spacing: -4px;
      margin-bottom: -2px;
      text-align: center;
      white-space: nowrap;
      span {
        position: relative;
        top: -1px;
      }

      &:hover {
        opacity: 0.7;
      }
      
      @media (max-width: 460px) {
        font-size: 14px;
      }
      @media (max-width: 380px) {
        font-size: 12px;
        margin-left: 5px;
        margin-right: 10px;
      }
    }

    .headerWallet {
      letter-spacing: 0.03em;
      width: 200px;
      padding: 16px;
      font-size: 12px;
      font-family: "Roboto Medium", monospace;
      line-height: 12px;
      transition: all 0.1s linear;
      color: black !important;
      background-color: var(--lightYellow);
      border-radius: 64px;
      &:hover {
        transform: scale(1.03);
        background-color: var(--darkYellow);
      }
      @media (max-width: 560px) {
        width: 150px;
        padding: 10px;
      }
      @media (max-width: 360px) {
        width: 130px;
        padding: 10px;
        font-size: 10px;
      }
    }

    .accountWrapper {
      display: flex;
      font-family: "Roboto Medium", monospace !important;
      align-items: center;
      justify-content: center;
      width: 200px;
      padding: 16px 15px;
      background-color: var(--lightYellow);
      border-radius: 64px;
      cursor: pointer;
      font-size: 16px;
      letter-spacing: 0.03em;
      transition: all 0.2s linear;

      &:hover {
        background-color: var(--darkYellow);
        transform: scale(1.03);
      }
    }
  }

}

.disconnectWallet {
  font-weight: bold;
  padding: 5px;
}


.paper {
  width: 200px !important;
  text-align: center !important;
  margin-top: 5px !important;
  margin-left: -12px !important;
  border-radius: 64px !important;
  padding: 10px 5px !important;
}
