@import "../../fluidType.scss";

.claimWrapper {
  @include fluidType(margin-right, 390px, 1365px, 8px, 24px);
  margin-right: 24px;
  margin-top: 80px;
  @media (max-width: 860px) {
    margin-right: 8px;
  }
  padding: 0 24px 0 0;
  @include fluidType(width, 390px, 1365px, 315px, 474px);
  margin-bottom: 48px;

  @media (max-width: 540px) {
    width: 100% !important;
  }

  .claimLabel {

    width: 100%;
    font-size: 52px;
    line-height: 52px;
    font-weight: 400;
    opacity: 15%;
  }

  .rewardsCount {
    max-width: 426px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 500;
    @include fluidType(font-size, 390px, 1365px, 48px, 52px);
    @include fluidType(line-height, 390px, 1365px, 48px, 52px);
    margin-top: 8px;
    margin-bottom: 32px;
    white-space: nowrap;
  }
}

.btnWrapper {
  width: 235px;
  @media (max-width: 540px) {
    width: 100% !important;
  }
}
