.timeOver {
}

.timesLeft {
  display: inline;
  background-color: var(--darkBlue);
  border-radius: 16px;
  color: white;
  padding: 3px 12px;

  span {
    opacity: 0.4;
  }
}

.tvlWrapper {
  margin-top: 20px;
}

.tvlLoading {
  opacity: 0.4;
}

.tvlLoading {
  display:inline-block;
  clip-path: inset(0 3ch 0 0);
  animation: l 2s steps(4) infinite;
}

@keyframes l {
  to {
    clip-path: inset(0 -1ch 0 0)
  }
}