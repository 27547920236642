@import "../../fluidType.scss";

  .tokensWrapper {
    margin-right: 0;
    overflow: hidden;
    white-space: nowrap !important;
    -ms-overflow-style: none;
    max-height: 310px !important;
    overflow-y: auto;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }

    @media (max-width: 1210px) {
      overflow-x: auto;
    }
    .tokensTabs {
      width: 731px;
      padding-bottom: 4px;
      display: flex;
      flex-wrap: nowrap;
      margin: 0 8px 6px;
      border-bottom: 1px solid #B3B3B3;
    }
  }

.tokensPair {
  flex-basis: 150px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.tokensSupply {
  flex-basis: 295px;
  max-width: 295px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.tokensLiquidity {
  flex-basis: 280px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.tokensTabText {
  font-size: 12px;
  line-height: 14.4px;
  font-weight: 400;
  opacity: 0.5;
}

.tokenItem {
  display: flex;
  width: 747px;
  padding: 8px 12px;
  margin-right: -5px;
  margin-bottom: 12px;
  border-radius: 99px;
  transition: background-color 0.2s linear;
  cursor: pointer;
  &:hover {
    background-color: #F5F5F5;

  }
}

.tokenItemDisabled {
  opacity: 0.5;
  cursor: auto;
}


.tokenLogos {
  display: flex;
  align-items: center;
  margin-right: 7px;

  .tokenLogoImgFirst {
    border-radius: 50%;
    width: 14px;
    height: 14px;
    background-color: var(--milkShake);
    img {
      width: 100%;
      height: 100%;
    }
  }

  .tokenLogoImgSecond {
    border-radius: 50%;
    margin-left: -5px;
    width: 14px;
    height: 14px;
    background-color: var(--milkShake);
    img {
      width: 100%;
      height: 100%;
    }
  }
}

.tokenItemText {
  font-family: "SF Mono Medium", monospace !important;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.3px;
}
.tokenButton {
  width: 100%;
  justify-self: flex-end;
  white-space: nowrap;
  text-align: right;
  transition: all 0.2s linear;
  &:hover {
    transform: scale(1.03);
  }
  &:disabled {
    opacity: 0.4;
    &:hover {
      transform: scale(1);
    }
  }
}

.tokenLogoText {
  width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 10px;
}

.supplyWrapper {
  max-width: 285px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 10px;
  display: flex;
  .supplyText {
    display: block;
    max-width: 200px !important;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .supplyTextUsd {
    max-width: 117px;
    overflow: hidden;
  }
}

.liquidityWrapper {
  display: flex;
  max-width: 280px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis !important;

  .liquidityText {
    max-width: 210px;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
  .liquidityTextUsd {
    max-width: 100px;
  }
}

.space {
  display: inline-block;
  width: 6px;
}

