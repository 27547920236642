@import "../../fluidType.scss";

.container {
  width: calc(100% - 8px);
  height: auto;
  border-radius: 24px;
  background-color: var(--darkBlue);
  @include fluidType(padding, 690px, 1365px, 16px, 24px);
  color: white;
  margin-right: 8px;


  @media (min-width: 860px) {
    @include fluidType(height, 860px, 1365px, 509px, 400px);
    width: calc(100% - 24px);
    max-width: 811px;
    margin-right: 24px;
  }

  @media (max-width: 660px) {
    width: calc(100% + 8px);
    margin-left: -8px;
  }

  .chooseTokensLabel {
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 16px;
    opacity: 0.8;
  }
}

.myTokensWrapper {
  width: 100%;
  @include fluidType(height, 660px, 1365px, 118px, 100px);
  border-radius: 16px;
  background-color: white;
  color: black;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 16px;

  @media (max-width: 660px) {
    height: 124px;
  }

  .myTokensInfo {
    display: flex;
    justify-content: space-between;
    font-weight: 500 !important;

    h4 {
      font-weight: inherit;
    }

    .myTokensAmount {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }

  .chooseWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    @media (max-width: 660px) {
      height: 100%;
      align-items: flex-end;
    }

    .chooseToken {

      display: flex;
      align-items: center;
      max-width: 240px;
      cursor: pointer;
      flex-wrap: nowrap;
      @media (max-width: 560px) {
        width: 60px;
      }
      @media (max-width: 660px) {
        margin-bottom: 10px;
      }
      span {
        letter-spacing: -0.01em;
        font-size: 24px;
        font-weight: 500;
        line-height: 24px;
        white-space: nowrap;
        @media (max-width: 360px) {
          white-space: normal !important;
          text-align: center;
        }

      }
      .expandIcon {
        margin-left: 4px;
        transform: rotate(0deg);
        transition: all 0.2s linear;
      }
      .expandIconActive {
        transform: rotate(-180deg);
        transition: all 0.2s linear;
      }
    }

    .chooseTokenWide {
      display: flex;
      align-items: center;
      max-width: 240px;
      cursor: pointer;
      flex-wrap: nowrap;
      @media (max-width: 560px) {
        width: 200px !important;
      }
      @media (max-width: 390px) {
        width: 100px;
        white-space: normal !important;
      }
      @media (max-width: 660px) {
        margin-bottom: 10px;
      }

      span {
        letter-spacing: -0.01em;
        font-size: 24px;
        font-weight: 500;
        line-height: 24px;
        white-space: nowrap;
        @media (max-width: 360px) {
          white-space: normal !important;
          text-align: center;
          width: 90px;
        }

      }
      .expandIcon {
        margin-left: 4px;
        transform: rotate(0deg);
        transition: all 0.2s linear;
      }
      .expandIconActive {
        transform: rotate(-180deg);
        transition: all 0.2s linear;
      }
    }

    .inputWrapper {
      width: 70%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 36px;
      position: relative;
      z-index: 10;
      @media (max-width: 660px) {
        flex-direction: column-reverse;
        align-items: flex-end;
        height: 100%;
        min-height: 70px;
      }

      .amountInput {
        flex-grow: 1;
        color: black;
        font-family: "SF Mono Medium", monospace;
        height: 24px;
        text-align: right;
        margin-left: 10px;
        padding: 0;
        text-transform: uppercase;
        border: none;
        outline: none;
        font-size: 24px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.01em;
        &::placeholder {
          font-family: "SF Mono Bold", monospace;
          opacity: 0.5;
          padding-top: 8px !important;
        }
        &:disabled {
          background-color: transparent;
          &::placeholder {
            font-family: "SF Mono Bold", monospace;
            opacity: 0.5;
          }
        }
        @media(max-width: 660px) {
          width: 80%;
          margin-top: 35px;
        }
        @media(max-width: 560px) {
          width: 100%;
          height: 100%;
          margin-top: 30px;
        }
      }

      .amountInputApproved {
        width: 30%;
        color: var(--darkBlue);
        @media (max-width: 660px) {
          width: 100%;
          margin-top: 0;
        }
      }

      .approvedText {
        display: inline-block;
        color: white;
        font-size: 14px;
        font-weight: 500;
        line-height: 12px;
        padding: 12px 25px;
        border-radius: 50px;
        background-color: var(--darkBlue);
        margin-left: 15px;
        white-space: nowrap;
        @media (max-width: 660px) {
          padding: 15px 0 5px;
          background-color: transparent;
          color: var(--darkBlue);
          line-height: 16px;
        }
      }
    }


    .accordionContent {
      z-index: 100;
      position: absolute;
      top: 35px;
      margin: 0 auto 0 -16px;
      height: 0;
      width: 1px;
      overflow-y: auto;
      @media (max-width: 660px) {
        top: 67px;
      }
      @media (max-width: 360px) {
        top: 67px;
      }
    }

    .accordionContentActive {
      width: calc(100% + 32px);
      background-color: white;
      display: flex;
      flex-direction: column;
      max-height: 250px !important;
      height: 250px;
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
      padding: 20px 8px 8px;
      transition: height 0.2s linear;
    }
  }
}

.chooseTermWrapper {
  @include fluidType(height, 390px, 1365px, 156px, 116px);
  padding-bottom: 0;
  position: relative;
  margin-bottom: 35px;
  border-bottom-right-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  /*&::after {
    content: "";
    border-top: 5px solid white !important;
    position: absolute;
    left: 1px;
    bottom: -1px;
    width: 20px;
    height: 12.5px;
    background-color: black;
    border-bottom-left-radius: 16px !important;
    z-index: 0;
  }
  &::before {
    content: "";
    border-top: 6px solid white !important;
    position: absolute;
    right: 1px;
    bottom: -1.5px;
    width: 30px;
    height: 14px;
    background-color: #e3e3e3;
    z-index: 1;
    border-bottom-right-radius: 16px !important;
  }*/

  .dateInfo {
    display: flex;
    justify-content: space-between;
    font-weight: 500 !important;
    padding: 0;

    .monthInfoCount {
      display: flex;
      flex-direction: column;
      h4 {
        font-weight: inherit;
        padding-bottom: 5px;
      }

      .months {
        font-size: 24px;
        line-height: 24px;
        font-family: "SF Mono Medium", monospace;
        letter-spacing: -0.01em;
      }
    }

    .dateInfoCount {
      text-align: right;
    }
  }
}

.sliderWrapper {
  padding: 0 5px;
  margin-bottom: 0;
  width: calc(100% + 41px);
  margin-left: -19px;

}
.sliderWrapperEnd {
  width: calc(100% + 36px);
 margin-left: -14px;
}

.sliderRoot {
  box-sizing: border-box !important;
  padding: 0 !important;
  margin: -2px !important;
}

.thumb {
  width: 32px !important;
  height: 32px !important;
  background-color: white !important;
/*  padding-left: -10px !important;*/
  z-index: 5 !important;
  margin-top: 3px !important;

  &:active,
  &:focus,
  &:hover,
  &:focus-visible {
    box-shadow: none !important;
  }
  &::after {
    width: 0 !important;
    height: 0 !important;
    background-color: transparent !important;
    display: none !important;
  }
  &::before {
    display: none !important;
  }
}

.thumb1 {
  margin-left: 20px !important;
}

.thumb3 {
  transform: translateX(-35px) !important;
  top: -15px !important;
}

.sliderTrack {
  border: none !important;
  border-top: 7px solid white !important;
  background-color: black !important;
  height: 15px !important;
  overflow: hidden !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 15px !important;


}

.sliderTrackEnd {
  margin-left: -5px !important;
}

.sliderRail {
  background-color: #e3e3e3 !important;
  border-top: 7px solid white !important;
  border-bottom: none !important;
  /*
  border-radius: 0 !important;
  */
  height: 15px !important;
  opacity: 1 !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 16px !important;
  margin-left: 1px !important;
}
.sliderLabels {
  top: -30px !important;
  font-size: 12px !important;
  line-height: 14px !important;
  font-weight: 500 !important;
  &[data-index="0"] {
    padding-left: 40px !important;
  }
  &[data-index="2"] {
    transform: translateX(-25px) !important;
  }
}
.sliderLabelsEnd {
  top: -30px !important;
  font-size: 12px !important;
  line-height: 14px !important;
  font-weight: 500 !important;
  &[data-index="0"] {
    padding-left: 30px !important;
  }
  &[data-index="1"] {
    margin-left: -2.5px !important;
  }
  &[data-index="2"] {
    transform: translateX(-25px) !important;
  }

}

/*.markLabelActive[data-index="1"] {
  display: none !important;
  !*display: none !important;*!
}*/

.mark {
  display: none !important;
}

.mark[data-index="0"] {
transform: translateX(20px) !important;
}

.mark[data-index="2"] {
  transform: translateX(-20px) !important;
}

.sliderFocusVisible {
  box-shadow: none !important;
  &:active,
  &:focus,
  &:hover,
  &:focus-visible {
    box-shadow: none !important;
  }
}

.lpTokensInfo {
  width: 494px;
  margin-top: 30px;
  line-height: 17px;
  font-size: 14px;
  opacity: 0.8;
  margin-bottom: 34px;
  @media(max-width: 1150px) {
    margin: 30px auto 20px;
    text-align: center;
  }
  @media(max-width: 960px) {
    width: 100%;
  }
  @media(max-width: 860px) {
    width: 100%;
    text-align: left;
    margin-top: 44px;
    margin-bottom: 32px;
  }
  @media(max-width: 460px) {
    margin-bottom: 20px;
  }

  .lpTokensInfoBr {
    @media(min-width: 460px) {
      display: none;
    }
  }
}

.profitContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media(max-width: 1150px) {
    justify-content: center;
    flex-direction: column;
  }
  @media(max-width: 860px) {
    align-items: flex-start;
  }

  .profitWrapper {
    width: 400px;

    @media(max-width: 1150px) {
      margin-bottom: 10px;
    }

    @media(max-width: 1000px) {
      width: 100%;
      flex-basis: 100%;
      justify-self: center;
      margin: 0 auto 35px;
    }

    @media(max-width: 460px) {
      margin-bottom: 10px;
    }


    .profitItem {
      display: flex;
      padding-bottom: 4px;
      font-size: 14px;
      line-height: 18px;
      opacity: 0.8;
      @media(max-width: 1150px) {
        justify-content: center;
      }
      @media(max-width: 660px) {
        width: 100%;
        justify-content: space-between;
      }


      .profitItemTitle {
        display: inline-block;
        margin-right: 32px;
        width: 100px;
      }

      .profitSpex {
        white-space: nowrap;
        display: inline-block;
        width: 70px;
      }

      .profitSpexText {
        @media(max-width: 660px) {
          text-align: right;
        }
      }
    }

  }
  .profitWrapperWide {
    width: 200px;
    @media(max-width: 1000px) {
      width: 100%;
      flex-basis: 100%;
      justify-self: center;
      margin: 0 auto 35px;
    }
  }
}

.buttonWrapper {
  width: 265px;

  @media(max-width: 860px) {
    width: 100%;
    align-self: center;
  }

  @media(max-width: 390px) {
    width: 100%;
    align-self: center;
  }
}


.buttonsWrapper {
  display: flex;
  align-items: center;
  width: 500px;
  justify-content: flex-end;
  @media (max-width: 860px) {
    width: 100%;
    margin: 0 auto;
    justify-content: center;

  }
  @media (max-width: 580px) {
    justify-content: flex-start;
  }

  .approvedText {
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    font-family: "SF Mono Regular", monospace;
    margin-right: 12px;
  }

  .approvedButtonWrapper {
    width: 525px;
    @media (max-width: 860px) {
      width: 100%;

    }
  }
}


.tokenItemInfo {
  display: flex;
  align-items: center;
  @media (max-width: 660px) {
    margin-bottom: -7px;
  }
  .tokenLogos {
    display: flex;
    align-items: center;
    margin-right: 7px;

    .tokenLogoImgFirst {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: var(--milkShake);
    }

    .tokenLogoImgSecond {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      margin-left: -10px;
      background-color: var(--milkShake);
    }

  }

  .tokenItemCurrency {
    font-size: 14px;
    line-height: 14px;
    font-weight: bold;
    @media (max-width: 390px) {
      font-size: 16px !important;
    }
    @media (max-width: 560px) {
      display: none;
    }
  }
}

.expandIconActiveBottom {
  @media (max-width: 660px) {
    margin-top: 10px;
  }
}

.btnTimeOverWrapper {}