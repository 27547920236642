.accountDetailsWrapper {
  position: relative;
  width: 121px;
  height: 40px;
  background-color: var(--lightYellow);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px;
  cursor: pointer;
}

.identyicon {
  height: 22px;
  width: 22px;
  border-radius: 1.125rem;
  background-color: var(--lightYellow);
}

.iconWrapper {
  margin-right: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  img {
    width: 100%;
    height: 100%;
  }
}

.accountInfo {
  font-family: "Roboto Mono", monospace;
  font-size: 11px;
  font-weight: 400;
  letter-spacing: -0.01em;

  .accountInfoWallet {
    white-space: nowrap;
  }

  .accountNetwork {
    text-transform: capitalize;
    opacity: 0.7;
  }
}

.paper {
  margin-top: 12px !important;
  width: 280px !important;
  height: 200px !important;
  border-radius: 24px !important;
  padding: 18px !important;
  margin-left: -12px !important;
}

.paperHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 18px;
}

.closeIconWrapper {
  cursor: pointer;
}

.userInfoWrapper {
  width: 244px;
  height: 87px;
  border-radius: 20px;
  border: 1px solid lightGrey;
  padding: 18px;
  flex-direction: column;
  margin-bottom: 13px;
}

.networkInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  span {
    letter-spacing: -0.03em;
  }

  .networkImg {
    width: 15px;
    height: 15px;
  }
}

.accountActionWrapper {
  display: flex;
  align-items: center;

  .accountActionInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .actionUserIcon {
    margin-right: 4px;
  }

  .copyIcon {
    margin-left: 20px;
    svg {
      stroke: rgb(136, 141, 155);
      cursor: pointer;

      &:hover {
        stroke: black;
      }
    }
  }
}

.accountInfoWalletAction {
  margin-right: 20px;
}

.copiedText {
  padding: 5px 10px;
  color: white;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.8);
  width: 170px;
  position: absolute;
  right: 50%;
  bottom: -240px;
  border-radius: 20px;
}

.buttonsWrapper {
  display: flex;
  justify-content: space-between;

  .walletBtn {
    width: 117px;
    height: 26px;
    border-radius: 20px;
    font-size: 12px;
    font-weight: 500;
    color: black;
    background-color: var(--lightYellow);
  }

  .walletBtnBlack {
    color: white;
    background-color: black;

  }
}
