
.modalBg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  overflow-y: hidden;
  background-color: rgba(245, 245, 245, 0.7) !important;
  backdrop-filter: blur(12px) !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.modalContainer {
  position: relative;

  .modalClose {
    position: absolute;
    right: 0;
    top: -42px;
    background-color: white;
    padding: 8px 16px;
    border-radius: 64px;
    font-size: 12px;
    line-height: 12px;
    font-weight: bold;

    @media (max-width: 400px) {
      right: 18px;
    }
  }

}